<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <ViewChallenge 
      ref="challenge"
      :viewRouteData="{ name: 'profileFriendsChallengeView', params: { eventId: $route.params.eventId }, query: $route.query }" 
      :configRouteData="{ name: 'profileFriendsChallengeConfig', params: { eventId: $route.params.eventId }, query: $route.query }" 
      :org="null"
      />
  </div>      
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import ViewChallenge from "@/components/usergeneratedchallenges/ViewChallenge";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    ViewChallenge,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
    };
  },
  async mounted() {
    await this.getProfile();

    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
    
  },
  methods: {
    async getProfile() {
      if (this.user) {
        this.$refs.challenge.loadEvent(this.$route.params.eventId);
      }
    },

  },
  computed: {
    breadcrumbItems() {
      return [
        { text: 'My Profile', exact: true, to: { name: 'profile'} },
        { text: 'Friends', exact: true, to: { name: 'profileFriends' } },
        { text: 'Challenge', disabled: true },
      ];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id);
    next();
  },
};
</script>
